import React from 'react'
import ResumeImage from './ResumeImage'

const ResumeContent = () => {
    return (
        <div className='container' id='inicio'>
            <div className='resume-content'>
                <h1>Hola, soy Benjamin Evrard Cuadra</h1>
                <span>Junior Front-end Developer</span>
                <p>Soy Ingeniero en Informática con más de un año de experiencia en HTML, CSS, Java, JavaScript, JSP/Servlets, jQuery, y MySQL, como también conocimientos base de React.js y TypeScript.</p>
                <p>Durante mi experiencia trabajando como desarrollador, he puesto en práctica mis conocimientos sobre desarrollo Front end y Back end, como también mis habilidades de trabajo en equipo, adaptabilidad, organización de tareas y priorización de las mismas, destacado por mi mentalidad esforzada y visión simplificada de problemas.</p>
            </div>
            <ResumeImage />
        </div>
    )
}

export default ResumeContent