import React from 'react'

const ResumeImage = () => {
    return (
        <div className='resume-image'>
            <img src='https://media.licdn.com/dms/image/D4E03AQG1w0xkviPu-w/profile-displayphoto-shrink_800_800/0/1718154585819?e=1728518400&v=beta&t=-31VEq3JMICNrWly2mmNF6PzOCI9InLH8gWZco2vPjI' />
        </div>
    )
}

export default ResumeImage