import React from 'react'

const About = () => {
  return (
    <>
      <div className='component-vh'>
        <h1 className='works-title' id='sobremi'>Sobre mi</h1>
      </div>

    </>
  )
}

export default About