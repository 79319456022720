import React from 'react';
import Html_Icon from '../Technologies/Html_Icon';
import Css_Icon from '../Technologies/Css_Icon';
import JavaScript_Icon from '../Technologies/JavaScript_Icon';
import React_Icon from '../Technologies/React_Icon';

const Projects = () => {
  return (
    <>
      <div className='component-vh'>
        <h1 className='works-title' id='proyectos'>Proyectos</h1>
        <div className='card-container'>
          <div className='card'>
            <img className='work-img' src='https://unsplash.com/blog/content/images/size/w600/2024/02/Unsplash--Announcement--Blog--1.jpg'></img>
            <div className='card-content'>
              <h3>Buscador de imágenes con API de Unsplash</h3>
              <h4>
                <Html_Icon size={30} />
                <Css_Icon size={30} />
                <JavaScript_Icon size={30} />
                <React_Icon size={30} />

              </h4>
              <p>Actividad realizada en el curso "React - Guía definitiva: hooks router redux next +Proyectos" de Nicolas Schurmann, dentro del cual se implementa el consumo de microservicios de UNSPLASH con la finalidad de obtener imágenes según búsqueda.</p>
              <a href='https://trabajando.cgtdata.cl/portal/' className='btn'>Visitar</a>
            </div>
          </div>
          <div className='card'>
            <img className='work-img' src='https://redprofesionalesdemkt.com/wp-content/uploads/2021/05/carro-de-compras-online.jpg'></img>
            <div className='card-content'>
              <h3>Carrito de compras</h3>
              <h4>
                <Html_Icon size={30} />
                <Css_Icon size={30} />
                <JavaScript_Icon size={30} />
                <React_Icon size={30} />
              </h4>
              <p>Actividad realizada en el curso "React - Guía definitiva: hooks router redux next +Proyectos" de Nicolas Schurmann, dentro del cual se construyo un carrito de compras dinámico.</p>
              <a href='https://trabajando.cgtdata.cl/portal/' className='btn'>Visitar</a>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Projects