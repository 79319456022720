import './header.css';
import './content.css';
import NavBar from './components/NavBar';
import ResumeContent from './components/Home/ResumeContent';
import Work from './components/Work/Work';
import Projects from './components/Projects/Projects';
import Technologies from './components/Technologies/Technologies';
import About from './components/About/About';
import React, { useState } from 'react';


const Modal = ({ tittle, message, onClose }) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <h2>{tittle}</h2>
        <h4>{message}</h4>
        <button className='modal-btn' onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

function App() {

  const [isModalOpen, setModalOpen] = useState(true);

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div>
      {isModalOpen && <Modal tittle="Sitio en construcción" message="Afinando detalles formato web (formato móvil no disponible)" onClose={closeModal} />}
      <header>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" />
        <NavBar />
      </header>
      <ResumeContent />
      <Work />
      <Projects />
      <Technologies />
      <About />
    </div>
  );
}

export default App;
