import React from 'react';
import '../../content.css';

const Work = () => {
  return (
    <>
    <div className='component-vh'>
      <h1 className='works-title' id='trabajos'>Trabajos</h1>
      <div className='card-container'>
        <div className='card'>
          <img className='work-img' src='https://imgbum.jobscdn.com/postulantes/minisitios/images/13124415/Laborumslide.png'></img>
          <div className='card-content'>
            <h3>Portal laboral Supermercados CUGAT</h3>
            <h4>Junior Java Developer</h4>
            <p>Participe de forma activa en la creación de sistemas web asociados a la postulación a ofertas laborales (Portal web https://www.trabajando.cgtdata.cl/) implementando conocimientos sobre Front end y Back end (HTML, CSS, JavaScript, jQuery, JSP/Servlets, Java, y MySQL), como también sistemas internos de la empresa asociados a la gestión de personal, todo esto utilizando Jira para la asignación de historias de usuario y GitKraken como herramienta para el control de versiones.</p>
            <a href='https://trabajando.cgtdata.cl/portal/' className='btn'>Visitar</a>
          </div>
        </div>
      </div>
    </div>
      

    </>
  )
}

export default Work