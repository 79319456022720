import React from 'react'
import Html_Icon from './Html_Icon'
import Css_Icon from './Css_Icon'
import JavaScript_Icon from './JavaScript_Icon'
import React_Icon from './React_Icon'
import Java_Icon from './Java_Icon'
import MySQL_Icon from './MySQL_Icon'


const Technologies = () => {
  return (
    <>
      <div className='component-vh'>
        <h1 className='works-title' id='tecnologias'>Tecnologías Front end</h1>
        <div className='tec-icons-technologies'>
          <Html_Icon size={60} />
          <Css_Icon size={60} />
          <JavaScript_Icon size={60} />
          <React_Icon size={60} />
        </div>
        <h1 className='works-title'>Tecnologías Back end</h1>
        <div className='tec-icons-technologies'>
          <Java_Icon size={70} />
          <MySQL_Icon size={70} />
          <JavaScript_Icon size={60} />
        </div>
      </div>

    </>

  )
}

export default Technologies