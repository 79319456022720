import '../header.css';
import React, { useState } from 'react'
import { Link } from 'react-scroll'
import Github_Icon from './About/Github_Icon';
import Linkedin_Icon from './About/Linkedin_Icon';

function NavBar() {

    const [navbar, setNavbar] = useState(false);

    const links = [
        {
            id: 1,
            link: "Inicio",
            target: "inicio"
        },
        {
            id: 2,
            link: "Trabajos",
            target: "trabajos"
        },
        {
            id: 3,
            link: "Proyectos",
            target: "proyectos"
        },
        {
            id: 4,
            link: "Tecnologías",
            target: "tecnologias"
        },
        {
            id: 5,
            link: "Sobre mi",
            target: "sobremi"
        }
    ]

    const changeBackground = () => {
        if (window.scrollY >= 57) {
            setNavbar(true)
        } else {
            setNavbar(false)
        }
    }

    window.addEventListener('scroll', changeBackground)

    return (
        // <nav className={navbar ? 'active' : 'nav'}>
        <nav>
            <ul className='nav-links'>
                {links.map((x) => (
                    <div className='navLink'>
                        <Link to={x.target} smooth={true} duration={500} offset={-100}>{x.link}</Link>
                    </div>
                ))}
            </ul>
            <div>
                <Github_Icon size={40} />
                <Linkedin_Icon size={40} />
            </div>
        </nav>
    )
}

export default NavBar